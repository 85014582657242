<template>
  <v-select
    :label="kind === 'CRE' ? 'Tipo Ingreso' : 'Tipo Egreso'"
    :items="items"
    return-object
    item-text="reason"
    persistent-hint
    :loading="loading"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    ref="auto"
  >
  </v-select>
</template>

<script>
import rules from "@/utils/form.rules";
import requests from "@/services/requests";

export default {
  props: {
    value: { type: Object, required: true, default: Object },
    filterReason: { type: Boolean, required: false, default: false },
    kind: {
      type: String,
      validator: function (value) {
        return ["CRE", "DEB"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      uri: "",
      required: [rules.objectRequired],
    };
  },
  mounted() {
    this.displayList();
  },
  methods: {
    displayList() {
      let removeOutReason = [202];
      let removeInReason = [107];
      this.loading = true;
      if (this.kind === "CRE") {
        this.uri = "api/accounts/movement-reason/in/";
      } else {
        this.uri = "api/accounts/movement-reason/out/";
      }
      requests.get(this.uri).then((response) => {
        if (response.status == 200) {
          this.items = response.data;
          if (this.filterReason) {
            if (this.kind == "DEB") {
              this.items = this.items.filter(
                (item) => !removeOutReason.includes(item.id)
              );
            } else if (this.kind == "CRE") {
              this.items = this.items.filter(
                (item) => !removeInReason.includes(item.id)
              );
            }
          }
          this.loading = false;
        } else if (response.status == 400) {
          this.$toasted.global.info({
            message:
              "No tiene los permisos necesarios para visualizar los Tipos de Ingresos",
          });
          console.log(response);
        } else {
          console.log(response);
        }
      });
    },
  },
  watch: {
    reset: function () {
      this.$refs.auto.reset();
    },
  },
};
</script>
